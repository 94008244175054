
<template>
  <div style="height: 100vh" class="d-flex justify-content-center align-items-center">
    <b-spinner></b-spinner>
  </div>
</template>

<script>
import { isUserLoggedIn } from "@/auth/utils";

export default {
  async created() {
    let redirect = true;
    const isLoggedIn = isUserLoggedIn();
    if (isLoggedIn) {
      if (this.$route.query.code && this.$route.query.pin) {
        const response = await this.$Vouchers.getCollection({params: { code: this.$route.query.code }}, 'id');
        if (response.status === 200 && response.data["hydra:member"].length) {
          redirect = false;
          this.$router.push(
            `${response.data["hydra:member"][0]["@id"]}/edit?pin=${this.$route.query.pin}`
          );
        }
      }
    } else {
      if (this.$route.query.code) {
        redirect = false;
        window.location.href = `https://${process.env.VUE_APP_SITE_URL}/aktivacia-poukazu?code=${this.$route.query.code}`;
      }
    }
    if (redirect) {
      this.$router.push("/");
    }
  },
};
</script>
